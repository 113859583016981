import React from 'react';
import Layout from '../components/Layout';

function HomePage() {
  return (
    <Layout isHomePage>
      <div className="visuallyhidden">All These Animals</div>
    </Layout>
  );
}

export default HomePage;
